<template>
   <div id="big_box">
    <div id="small_box">
        <div id="form_box">
          <div id="form_box_title">登录</div>
          <!--
             :model 表单数据对象
             :rules 表单验证规则
          -->
          <el-form :model="form" :rules="rules" ref="loginForm">
            <el-form-item prop="name" >
              <el-input v-model="form.name" placeholder="用户名" prefix-icon="el-icon-user"></el-input>
            </el-form-item>
            <el-form-item prop="pwd">
              <el-input type="password" v-model="form.pwd" placeholder="密码" show-password prefix-icon="el-icon-lock"></el-input>
            </el-form-item>
            <el-form-item prop="code" >
              <el-input v-model="form.code" placeholder="code码" prefix-icon="el-icon-s-data"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSubmit">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
    </div>
   </div>
</template>

<script>
export default {
    data(){
        return {
            form:{
                name:"",
                pwd:"",
                code:"",
            },
          rules:{
              name:[
                { required: true, message: '请输入用户名', trigger: 'blur' },
              ],
              pwd:[
              { required: true, message: '请输入密码', trigger: 'blur' },
            ],
            code:[
              { required: true, message: '请输入code', trigger: 'blur' },
            ],
          }
        }
    },
    methods:{
         handleSubmit(){
           //规则验证通过才提交
           this.$refs.loginForm.validate(async (val)=>{
             if (val){
               //post执行后返回一个promise对象
               let resVal = await this.$http.post("/user/checkLogin",this.form).catch((err)=>{
                 console.warn(err)
               })
               let result = resVal.data
               if (result.code==0){
                 //登录态相关信息，交给vuex存储
               /*let result = {
                 data:{
                   name:"tony",
                   token:"xx"
                 }
               }*/
                 this.$store.commit("Login/getRealName",result.data)
                 await this.$router.push("/main_new");
               }else{
                 this.$message.error(result.msg)
                 return false
               }
             }else{
               this.$message.error("输入必填项")
               return false
             }
           })
         }
    }
}
</script>

<style scoped>
      #big_box{
        width:100vw;
        height:100vh;
        background:url(../assets/img/bgx.jpg) no-repeat;
        background-size:cover;
      }

      #small_box{
        width:100vw;
        height:100vh;
        position: absolute;
        left: 0;
        top:0;
        background-color:rgb(0,0,0,0.4);
      }

      #form_box {
        position: absolute;
        right: 10%;
        bottom: 45%;
        width: 500px;
        height: 300px;
        padding: 20px;
        background-color: #fff;
        border-radius: 10px;

      }
        #form_box_title{
          background-color: #67C23A;
          height: 45px;
          text-align: center;
          font-weight: bold;
          color:#fff;
          line-height: 45px;
          margin:-20px -20px 20px;
          border-radius: 10px 10px 0px 0px;
        }
      .el-button{
        width: 100%;
      }
</style>
